.Footer{
    background-color: #20284E;
    height: 250px;
    color: #FBF8F0;
    
}
.Footer .part-1{
    display: flex;
    align-items: center;
    height: 70%;
    border-bottom: 1px solid #ED5009;
}
.Footer .part-1 img{
    margin: 0 2rem ;
  cursor: pointer;
}
.Footer .list{
    display: flex;
    text-align: start;
    justify-content: center;
    gap: 5rem;
    width: 65%;
    height: 100%;
    margin-top: 10%;

}
.Footer .list li{
    list-style: none;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 0.3s ease-in;
}
.Footer .list li:hover{
    color: #ED5009;
}
.part-2{
   
    height: 30%;
}
.part-2 .terms{

    height: 35%;
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 1.5rem;
}
.part-2 .terms span{
    cursor: pointer;
    transition: 0.3s ease-in;
}
.part-2 .terms span:hover{
    color: #ED5009;
}
.part-2 .terms strong{
   font-weight: 900;
   color: #ED5009;
}
.part-2 .socials{
 
    height: 65%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: end;
    padding-right: 3rem;
}
.part-2 .socials svg{
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s ease-in;
}
.part-2 .socials svg:hover{
    color: #ED5009;
}

@media (max-width:600px) {

  
   .part-1 img{
    height: 20px;
   }
   .Footer .list{

    gap: 3rem;


}
    }
    @media (max-width:450px) {

  
        .part-1 img{
         height: 17px;
        }
        .Footer .list{
     
         gap: 1.5rem;
     
     
     }
         }