.Limited{
 
    display: flex;

    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
   
}
.lin-area{
    width: 70%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 200px;
    border-radius: 1rem;
    box-shadow: 0px 10px 10px #D9D9D9;
}
.Limited h2{
    color:#4E62D6 ;
}
.Limited .orange{
    color:#ED5009 ;
    font-weight: 500;
}
.Limited p{
    padding: 0 1rem ;
}
@media (max-width:500px) {
    .lin-area{
        width: 90%;
        
    }
}