.otp-input-area .overlay{
    background-color: rgba(0, 0, 0, 0.155);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    }
    .otp-input-area .input-area{
        background-color: antiquewhite;
        position: absolute;
        z-index: 2;
        transform: translateX(-50%); 
        left: 50%; 
        top: 30%;
        height: 200px;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
