.Article{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
}
.arti-area{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}
.arti-area .arti{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 32rem;
    text-align: justify;
    box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.062);
}
.arti-area .arti .arrow{
   margin-left: 80%;
  margin-top: 1rem;
   cursor: pointer;
}

.arti-area .arti .arti-txt{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
}
.arti-area .arti span{
    font-weight: bold;
    font-size: 1.1rem;
    
}
.arti-area .arti p{
  
    font-size: 15px;
}
.arti img{
height: auto;
width: 90%;
}
@media (max-width:500px) {
    .arti-area .arti .arrow{
        width: 90px;
        margin-left: 70%;
     }
}