.Reviews{
    height: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  justify-content: center;
align-items: center;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
   
    background-color: white;
}
.Reviews h1{
    margin-bottom: 2rem;
}
.customer-video{
scroll-behavior: smooth;

padding-left: 2rem;
overflow-x: hidden;
align-items: center;
justify-content: center;
}
.customer-video img{
    height: 30rem;
    object-fit: cover;

}
/* .Reviews p{
    width: 400px;
} */
.review-arrows{
    display: flex;
    gap: 4rem;
    cursor: pointer;
}
/* .Reviews .review-text{
    background-color: yellowgreen;
    display: flex;
} */
/* .Reviews .lower{
   margin-left: 40%;
   font-size: 5rem;
  text-align: end;
  display: flex;
align-items: center;
justify-content: center;
color: gray;
padding-top: 2rem;
}
.Reviews .upper{
    margin-right: 100%;
    font-size: 5rem;
    height: 10%;
    display: flex;
    align-items: flex-end;
    color: gray;
} */
@media (max-width:900px) {
    .customer-video{
        width: 420px;
        padding-left: 1rem;
    }
    .customer-video img{
        height: 400px;
    }
}