.mainTamp-two{
    /* background-color: white; */
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  /* color: white; */
  border-radius: 20px;
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 1);
  border: 1px solid #bebebe;
  background-color: white;
}
.mainTamp-two p{
    /* color: white; */
    margin: 0 1rem ;
}
.mainTamp-two img{
    height: 60px;
    background-color: rgba(255, 255, 255, 0.523);
    padding: 0.7rem;
    border-radius: 50%;
    object-fit: cover;
}
