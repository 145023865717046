.page-body{
    display: flex;
    flex-direction: column;
   gap: 2rem;
   margin-bottom: 2rem;
}
.page-body img{

}
.page-body .page-text-area{

    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.page-text-area .p-area{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.page-text-area .p-area p{
   color: black;
    text-align: justify;
}
.page-text-area .agenda-area{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 2rem;
}
.page-text-area .agenda-area h3{
    font-size: 1.6rem;
   border-left: 8px solid #ed5009;
   padding-left: 0.3rem;
}
.page-text-area .agenda-area strong{
    font-size: 1.2rem;
}
.page-text-area .agenda-area ul{
   
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.page-text-area .agenda-area li{
    font-size: 15px;
}
.page-text-area .agenda-area p{
    font-size: 15px;
    text-align: justify;
    color: black;
}
/* table */

.agenda-area table th{
text-align: start;
padding-bottom: 2rem;
font-size: 1.5rem;
}
.agenda-area table td{
    font-size: 1.2rem; 
    padding-bottom: 1rem;
}

.page-body .trouble-area{
    display: flex;
    width: 70%;
    gap: 5rem;

}
.page-body .trouble-area img{
    height: 400px;
}
.page-body .trouble-area .trouble-txt{
    display: flex;
    flex-direction: column;
    gap: 2rem;

}
.page-body .trouble-area .trouble-txt h3{
    font-size: 1.6rem;
}
.page-body .trouble-area .trouble-txt p{
    font-size: 15px;
}
.page-body .speakers{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.speakers .individual-speaker{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 300px;
    gap: 0.5rem;
}
.speakers .individual-speaker img{
    height: 200px;
}

@media (max-width:1200px) {
    .page-body .trouble-area{
        width: 90%;     
    }
}

@media (max-width:1000px) {
    .page-body .trouble-area{
       align-items: center;   
    }
    .page-body .trouble-area{
      flex-direction: column;   
    }
    .page-body .trouble-area img{
        width: 300px;
    }
}
@media (max-width:700px) {
    .page-text-area .p-area{
        width: 90%;
       
    }
    .page-text-area .p-area p{
        font-size: 15px;
   
    }
    .page-text-area .agenda-area{
        width: 90%;
      
    } 
    .page-text-area .agenda-area p{
        font-size: 15px;
    }
    .page-text-area .agenda-area h3{
        font-size: 1rem;
      
  
    }
}
@media (max-width:500px) {

    .page-body .trouble-area img{
        width: 250px;
        height: 300px;
    }
    .page-body .speakers{

        gap: 1rem;
    }
    .speakers .individual-speaker{
    
        width: 90%
      
    }
}