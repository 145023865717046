.takeaways{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.way-points{
    display: flex;
    align-items: flex-start;
    justify-content:space-between;
    width: 60%;  
    gap: 3rem;
}
.way-text{
    width: 100%;
}
.point{
  
   width: 100%;
   
   display: flex;
   flex-direction: column;
   gap: 2rem;
}

.point .point-item{
background-color: white;
height: 4rem;
display: flex;
align-items: center;
border-radius: 0.7rem;
gap: 1rem;
padding-left: 1rem;
box-shadow: 0px 4px 20px #6363631F;
text-align: start;
}
.point .point-item img{
    height: 40px;
}
.point .point-item p{
    font-size: 0.9rem;
    font-weight: 600;
}


@media (max-width:1000px) {
    .way-img img{
        height: 350px;
    }
    .way-img {
        display: flex;
align-items: center;
justify-content: center;
width: 100%;
    }
    .way-points{
      
        flex-direction: column;
        justify-content:center;
        gap: 2rem;
        width: 80%;  
    }
   
}
@media (max-width:500px) {
    .way-img img{
        height: 300px;
    }

   
}