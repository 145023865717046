.Features{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.feature-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

}
.fe-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.feature-list h3{
    font-size: 1.2rem;
}
.feature-list p{
 
    font-size: 0.9rem;
    width: 80%;
}
.fe-item img{
    height: 40px;
    background-color: #ED50091F;
    padding: 1rem;
    border-radius: 50%;
}
/* @media (min-width:1700px) {
    .feature-list{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }
    .feature-list h3{
        font-size: 1.3rem;
    }
    .feature-list p{
     
        font-size: 0.9rem;
        width: 90%;
    }
} */