.School{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: start;
   
}
.school-circle{
    position: absolute;
    height: 100px;
    left: -10%;
    bottom: 5%;
}
.School h3{
    font-weight: bold;
    font-size: 1.5rem;
    z-index: 2;
}
.School p{
    width: 60%;
    z-index: 2;
}
@media (max-width:1000px) {
    .School p{
        width: 90%;
       margin-left: 3rem;
    }
    .School h3{
        margin-left: 3rem;
        font-size: 1.3rem;
       
    }
}
