.Home{
    display: flex;
    flex-direction: column;
  gap: 3rem;
}
.our-program{
    display: grid;
 
    grid-template-columns: 10fr 1fr;
}

.our-products{
    display: grid;
    grid-template-columns:1fr 10fr ;
 
}

@media (max-width:900px) {
    .img-item{
   display: none;
    }
    .our-program{    
        grid-template-columns: 1fr;
    }
    .prosheader{
        display: none;
    }
    .our-products{
        display: grid;
        grid-template-columns:1fr ;
     
    }
}