.banner{  
   width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
 height: 60vh;

}

.hero{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 8%;
    height: 100%;
}
.hero-text {
    text-align: start;
    letter-spacing: 1px;
}
.hero-text h1{
    font-size: 3rem;
    font-weight: 700;
}
.hero-text p{
    font-size: 1.5rem;
    font-weight: 500;
    color: #4D4D4D;
}
.hero-text button{
    height: 50px;
    width: 200px;
    border: 1px solid #ED5009;
    background-color: #4E62D6;
    color: white;
    border-radius: 2px;
    box-shadow: 7px 7px 0px rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
   margin-bottom:  10%;
   margin-top:22%;
   margin-left: 40%;
}
.hero-text button svg{
    color: #ED5009;
    font-size: 1.2rem;
}
.hero-img img{
    height: 500px;
}

.ath-txt{

}

@media (max-width:1300px) {
    .banner{
        margin-top: 3rem;
    }
    .hero{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 8%;
    width: 72%;
     height: 100%;
    }
    .hero-img img{
        object-fit: contain;
        height: 24rem;
    }
    .ath-banner .ath-img{
        height: 270px;
     
    }
    .ath-txt button{


        margin-top:15%;
        margin-left: 40%;
     }
}
@media (max-width:1000px) {
    .banner{  
      
      height: 70vh;
     }

    .hero-img img{
        height: 300px;
    }
    .hero{
        flex-direction: column-reverse;
        gap: 2rem;
        align-items: center;
    }
    .hero-text {
        text-align: center;        
    }
    .hero-text button{
    
       margin-top: 0rem;
       margin-left: 4.5rem;
    }
    .hero-text h1{
        font-size: 2.5rem;

    }
    .hero-text p{
        font-size: 1.3rem;
       
    }
}

@media (max-width:500px) {
    .banner{  
      
      height: 60vh;
     }
     .hero-text button{
    
        margin-top: 0rem;
        margin-left: 2rem;
     }
     .hero-img img{
        height: 250px;
    }
    .ath-banner img{
        height: 220px;
    }
    .hero-text p{
        font-size: 1rem;      
    }
    .ath-banner .ath-img{
        height: 220px;
     
    }
    }