.Login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    height: 80vh;
  
}
.Login .login-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    width: 100%;
 
}
.Login .login-area .success{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    position: absolute;
    transform: translateX(-50%); 
    left: 50%; 
    top: 10%;
    width: 25rem;
    height: 3rem;
    text-align: start;
    border-bottom: 3px solid rgb(5, 192, 5);
}

.Login .login-area .success svg{
    font-size: 1.5rem;
    cursor: pointer;
}
.Login .login-area .heading-area{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.Login .login-area span{
    cursor: pointer;
    color: #ED5009 ;
}
.Login .login-area .inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    width: 100%;
 
    width: 
}
.Login .login-area .inputs .input-error{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
   
}
.Login .login-area .inputs .input-error span{
    text-align: start;
    color: red;
    margin-left: 0.5rem;
    width: 50%;

   
}
.Login .login-area .inputs input{
    height: 3rem;
    width: 20rem;
    border: 1px solid black;
    border-radius: 0.7rem;
    background-color: inherit;
    padding-left: 2rem;
    outline: none;
}
.Login .login-area .button-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.Login .login-area .button-area input{
  height: 3rem;
  width: 15rem;
  border-radius: 0.7rem;
  border: none;
  background-color: #FD8C69;
  cursor: pointer;
}
.button-area button{
    background-color: #FD8C69;
    width: 12rem;
    height: 3rem;
    border-radius: 0.7rem;
    border: none;
}
.Login .main-area{
display: flex;
flex-direction: column;
gap: 2rem;
}
.main-area .or-area{
 
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}
.main-area .or-area span{
    color: black;
}
.main-area .or-area .icons-area{
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.main-area .or-area .icons-area img{
    cursor: pointer;
}
.main-area .or-area .sign-mob{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    font-weight: 600;
}