
.App-header{
    position: relative;
    background-color: #FBF8F0;
}
.top-content {
    text-align: center;
}
.top-content h1{
    font: League Spartan;
    font-weight: 600;
    font-size: 64px;
    color: #B20149;
    margin: 20px 20px 20px 20px;
}
.top-content h2{
    font: Urbanist;
    font-weight: 600;
    font-size: 36px;
    color: #121942;
    margin: 20px 20px 20px 20px;
}
.top-content p{
    font: Urbanist;
    font-weight: 500;
    font-size: 20px;
    color: #525252;
    margin: 20px 20px 20px 20px;
}
.split-screen-container {
    display: flex;
    height: 110vh; 
  }
.demo-video{
    flex: 1;
   
    padding: 20px;
    box-sizing: border-box;
    margin: 20px 20px 20px 20px;
}
.demo-video h1{
    margin: 20px 20px 20px 20px;
    font-size: 140px;
}
.demo-video h2{
    margin: 20px 20px 20px 20px;
    font-size: 37px;
}
.border-wrapper {
    display: inline-block;
    padding: 21px;
    border-radius: 21px;
    border: 1px solid #ED5009;
}
.video-wrapper {
    overflow: hidden;
    position: relative;
}
.video-wrapper video {
    object-fit: cover;
    border-radius: 18px;
}
.play-button-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 132px;
    height: 132px;
  }

.demo-video button{
    margin: 20px 20px 20px 350px;
    height: 50px;
    width: 200px;
    border: 1px solid #ED5009;
    background-color: #4E62D6;
    color: white;
    border-radius: 2px;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
}
.our-video {
    flex: 1;
    margin: 20px 20px 20px 20px;
}
.countdown-timer {
    position: absolute;
    color: black;
    padding: 10px;
    border-radius: 8px;
    font-size: 50px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    justify-content: center;
}
.countdown-timer p {
    margin: 0px 0px 800px 0px;
    font-size: 50px;
}
.countdown-timer p1 {
    margin: 0px 0px 800px 0px;
    font-size: 50px;
}
