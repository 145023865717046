.Introduction{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  padding: 2rem 0;
    background-color: white;
    position: relative;
}
.Introduction h2{
    font-size: 2rem;
    font-weight: bold;
    
}
.Introduction .img-text-area{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}
.Introduction .img-text-area img{
    margin-right: 7rem;
    height: 24rem;
}
.Introduction .img-text-area span{
    position: absolute;
    left: 23rem;
    bottom: 2rem;
   text-align: justify;
    width: 70%;
    font-size: 1.2rem;
}
@media (max-width:1000px) {
    .Introduction .img-text-area img{
        margin-right: 7rem;
        height: 18rem;
    }
    .Introduction .img-text-area span{
     font-size: 0.9rem;
     left: 15rem;
     bottom: 2rem;
      
    }
}
@media (max-width:550px) { 
    .Introduction .img-text-area img{
        margin-right: 5rem;
        height: 12rem;
    }
    .Introduction .img-text-area span{
        bottom: -14%;
        left: 48%;
        width: 50%;
    }
    .Introduction .for-work span{
        position: absolute;
        left: 50%;
        bottom: 7%;
       text-align: justify;
        width: 40%;
        font-size: 0.7rem;
    }
    .Introduction .for-work img{
        margin-right: 0rem;
        height: 12rem;
     
    }
}