.thank-you {
    background-color: #FBF8F0;
}

.thank-you h1 {
    font: "Poppins";
    font-weight: 600;
    font-size: 133.8px;
    color: #B20149;
    margin: 20px 20px 20px 20px;
}

.thank-you p {
    font: Urbanist;
    font-weight: 600;
    font-size: 36px;
    color: #000000;
    margin: 20px 20px 20px 20px;
}

.thank-you button {
    margin: 20px 20px 20px 800px;
}