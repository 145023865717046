.Register-nu{
    display: flex;
    align-items: center;
    justify-content: center;   
}
.Register-nu .reg-area-nu {
display: flex;

align-items: center;
justify-content: center;   
}
.reg-img-nu img{
    height: 20rem;

}
.reg-text-ath-nu{

    width: 30%;
    text-align: justify;
}
.reg-text-ath-nu p,.reg-text-ath-nu h2{
    margin-left: 10%;
}
@media (max-width:1000px) {
    .Register-nu .reg-area-nu {
      
        flex-direction: column; 
        align-items: flex-start;     
        gap: 1rem;
        }
        .reg-text-ath-nu{

            width: 95%;
            text-align: justify;
        }
        .reg-text-ath-nu p,.reg-text-ath-nu h2{
            margin-left: 0%;
       
        }
}
@media (max-width:500px) {
    .reg-img-nu img{
        height: 12rem;
        margin-left: 5%;
    }
    .reg-text-ath-nu p,.reg-text-ath-nu h2{
        margin-left: 5%;
   
    }

}