.Squats{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Squats .ex-area{
    width: 80%;
}
@media (max-width:1100px) {
    .Squats .ex-area{
        width: 95%;
    }
}
@media (max-width:600px) {
    .Squats .ex-area{
        width: 100%;
    }
}