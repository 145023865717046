.benefits{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 3rem;
}

.executives{
    height: 200px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.executives p{
    text-align: center;
    width: 70%;
    font-size: 1.3rem;
    font-weight: 600;
    z-index: 2;
}
.yellow-arrow{
    position: absolute;
    height: 60px;
    top: 0;
    left: 20%;
}
.peach{
    position: absolute;
    height: 80px;
    left: 30%;
    top: 60%;
   
}
.yellow{
    position: absolute;
    height: 80px;
    top: 60%;
    right: 30%;
}

.orange-arrow{
    position: absolute;
    height: 60px;
    top: 25%;
    right: 20%;
}
.empowering {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  flex-wrap: wrap;
  margin: 3rem 0;
}
.empowering .emp-list{
    background-color: #FBF8F0;
    height: 160px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 35px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); 
}
.empowering .emp-list .emp-img{
background-color:#C7CDEF ;
width: 70px;
height: 60px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
}
@media (max-width:1500px) {
    .executives p{
  
        width: 90%;
        font-size: 1.2rem;
   
    }
}

@media (max-width:500px) {
    .executives{
        width: 90%;
    }
    .executives p{
  
        width: 100%;
        font-size: 1rem;
   
    }
    .benefits{
  
        padding: 0rem;
        padding-bottom: 1rem;
    }
    .yellow-arrow{
        position: absolute;
        height: 40px;
        top: 0;
        left: 0;
    }
    .peach{
        position: absolute;
        height: 50px;
        left: -10%;
        top: 60%;
       
    }
    .yellow{
        position: absolute;
        height: 50px;
        top: 60%;
        right: 0;
    }
}