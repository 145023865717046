.programs{
    display: flex;

}
.two-programs{
    width: 60%;
   display: flex;
   flex-direction: column;
   gap: 3rem;
    text-align: start;
}
.space{
    width: 20%;  
}
.program-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

}
.program-detail{
    text-align: justify;
    width: 900px;
    line-height: 1.1;
   
    width: 50%;
}
.program-text{
    display: flex;
    align-items: center;
   justify-content: space-between;
   font-size: 0.8rem;
   margin: 0 0.8rem;
 
}
.program-text p{
    font-weight: 900;
    font-size: 0.8rem;
}
.program-text span{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in;

}
.program-text span:hover{
    color: #ED5009;
}
.program-detail h1, .program-detail2 h1{
    margin-bottom: 1rem;
}
.program-detail2{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: justify;
    padding-left: 22rem;

}
.program-detail2 p{

    width: 100%;

}
@media (max-width:900px) {
.two-programs{
    width: 80%;

}
.program-detail2{
   
    padding-left: 0rem;

}
.program-detail{
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
 
}
    .program-list{
        justify-content: center;
     
    }

    .two-programs{
    
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       gap: 1rem;      
        width: 100%;
    }
    
    .program-detail2{
        width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
 
  
  
    }
    .program-detail2 p{
   
        width: 100%;
    }
   .program-detail p{
 
        width: 80%;
    }
}
@media (max-width:600px) {
   
    .space{
       display: none;

    }
    .program-detail{
        align-items: center;
        justify-content: center;
    }
    .program-detail p{
      
        padding: 1rem 2rem;
 
        
    }
  

    .two-programs{
    
     
        width: 100%;
    }
}
